import produce from "immer";
import {getType} from "typesafe-actions";
import {CallType} from "@sense-os/goalie-js";
import {agoraActions, AgoraActionType} from "./agoraActions";
import {AgoraState} from "./types";
import {extractRoomIdFromText, isVideoCallType} from "../helpers/meetingHelpers";
import {LoadingState} from "constants/redux";

const initialState: AgoraState = {
	isAgoraWindowOpen: false,
	windowAction: null,
	createdMeetingLink: null,
	roomId: null,
	joinedTime: null,
	leaveTime: null,
	isInvitationDialogOpen: false,
	invitationLink: null,
	invitationCreator: null,
	isJoinWithVideo: null,
	channelLoadingState: LoadingState.EMPTY,
	channelId: null,
	rtcToken: null,
	callType: undefined,
};

const agoraReducer = produce((state: AgoraState = initialState, action: AgoraActionType): AgoraState => {
	switch (action.type) {
		case getType(agoraActions.openAgoraWindow.request):
			state.windowAction = action.payload.windowAction;
			state.roomId = extractRoomIdFromText(action.payload.meetingText) || null;
			state.createdMeetingLink = null;
			state.joinedTime = null;
			state.leaveTime = null;
			state.isJoinWithVideo =
				isVideoCallType(action.payload.meetingText) || action.payload.callType === CallType.Video;
			state.channelId = action.payload?.channelId;
			return;
		case getType(agoraActions.openAgoraWindow.success):
			state.isAgoraWindowOpen = true;
			return;
		case getType(agoraActions.resetAgoraWindowState):
			state.isAgoraWindowOpen = false;
			state.windowAction = null;
			state.roomId = null;
			return;
		case getType(agoraActions.openInvitationDialog):
			state.isInvitationDialogOpen = true;
			state.invitationLink = action.payload.invitationLink;
			state.invitationCreator = action.payload.invitationCreator;
			state.channelId = action.payload.channelId;
			state.callType = action.payload.callType;
			return;
		case getType(agoraActions.closeInvitationDialog):
			state.isInvitationDialogOpen = false;
			state.invitationLink = null;
			state.invitationCreator = null;
			return;
		case getType(agoraActions.userCreatedMeeting):
			state.createdMeetingLink = action.payload.createdLink;
			return;
		case getType(agoraActions.userJoinedMeeting):
			state.joinedTime = action.payload.joinedTime;
			return;
		case getType(agoraActions.userLeaveMeeting):
			state.leaveTime = action.payload.leaveTime;
			return;

		case getType(agoraActions.createAgoraChannel.request):
			state.channelLoadingState = LoadingState.LOADING;
			return;
		case getType(agoraActions.createAgoraChannel.success):
			state.channelLoadingState = LoadingState.LOADED;
			state.channelId = action.payload.agoraChannel.id;
			state.rtcToken = action.payload.agoraChannel.rtcToken;
			return;
		case getType(agoraActions.createAgoraChannel.failure):
			state.channelLoadingState = LoadingState.ERROR;
			return;

		case getType(agoraActions.getAgoraChannel.request):
			state.channelLoadingState = LoadingState.LOADING;
			return;
		case getType(agoraActions.getAgoraChannel.success):
			state.channelLoadingState = LoadingState.LOADED;
			state.channelId = action.payload.agoraChannel.id;
			state.rtcToken = action.payload.agoraChannel.rtcToken;
			return;
		case getType(agoraActions.getAgoraChannel.failure):
			state.channelLoadingState = LoadingState.ERROR;
			return;

		case getType(agoraActions.setRtcToken):
			state.rtcToken = action.payload.rtcToken;
			return;
	}
}, initialState);

export default agoraReducer;
